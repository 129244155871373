import axios from 'axios';
import { useState, useEffect } from 'react';
import FullPageLoader from './FullPageLoader';
import Message from './Message';
import Error from './Error';
import './main.css';
import ConsentModal from './ConsentModal';
import Form from 'react-bootstrap/Form';

const Home = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [isTranslated, setIsTranslated] = useState(true);

    const [clientName, setClientName] = useState('finfra');

    const [info, setInfo] = useState({user_id: "", user_name: "", user_email: ""});

    const [isMessage, setIsMessage] = useState(false);

    const [isError, setIsError] = useState(false);

    const [check, setCheck] = useState(false);

    const [isConsent, setIsConsent] = useState(false);

    const [showCountryCode, setShowCountryCode] = useState(true);

    const [isConfigData, setConfigData] = useState({["client"]: 'finfra'});

    const isDevelopment = window.location.href.includes('sog3xlfxxq') || window.location.href.includes('finfra-139740815252.asia-southeast2');
    const baseURL = isDevelopment ? 'https://mock.powercred.io' : 'https://dev.powercred.io';
    

    const translate = (lang) => {
        if((lang == 'en' && !isTranslated) || (lang == 'id' && isTranslated)){
            return;
        }
        setClientName(lang == 'en' ? 'finfra' : 'finfra');
        setIsTranslated(!isTranslated);
    };

    const changeValue = (key, value) => {
        if(key == "user_id"){
            value = value.trim();
        }
        setInfo({...info, [key]: value});
        setConfigData({...isConfigData, [key]: value});
    };

    const isInteger = (value) => {
        return /^\d+$/.test(value);
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      const masterConsent = () => {
        setIsConsent(true);
        return;
    }

    const generateToken = async() => {
        if(!info.user_name){
            setIsMessage(isTranslated ? 'Silakan masukkan Nama untuk melanjutkan' : 'Please enter Name to proceed' );
            return;
        }
        else if(!info.user_id){
            setIsMessage(isTranslated ? 'Silakan masukkan Nomor Telepon untuk melanjutkan' : 'Please enter Phone Number to proceed' );
            return;
        }
        else if(info.user_id.length < 9){
            setIsMessage(isTranslated ? 'Harap periksa kembali nomor telepon Anda' : 'Please check your Phone number again');
            return;
        }
        if(info.user_email){
            if(!validateEmail(info.user_email)){
                setIsMessage(isTranslated ? 'Silakan periksa kembali Email Anda' : 'Please check your Email again');
                return;
            }
        }
        if(!check){
            setIsMessage(isTranslated ? 'Silakan terimalah Kebijakan Perlindungan dan Berbagi Data' : 'Please accept the data sharing and protection policies.' );
            return;
        }
        
        setIsLoading(true);

        const config = {
            method: 'POST',
            url: '/auth/token',
            baseURL,
            params: {
                apikey: 'WAGgmc8MT2o5zFWFyMyxpBtR7YeTOCyurL0yKWZuHkPZroo0',
                secret: '0p4lYMVk4jn1qaNy1yKi49VMcJrLwvxraEXSo7ipuntBEUGYJ7eUCNAiALK9RIBl'
            },
            data: isConfigData
        };  

        try{
            const response = await axios(config);
            // console.log(response.data.url);
            window.open(response.data.url, '_self');
            window.gtag('event', 'frontPage_continue_clicked', {
                'partner_name': 'finfra',
                'access-token-generation': 'Success'
            });
        }
        catch(e){
            console.log('error ', e);
            window.gtag('event', 'frontPage_continue_clicked', {
                'partner_name': 'finfra',
                'access-token-generation': 'Failed'
            });
            setIsError(true);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        window.gtag('event', 'frontPage_visited', {
            'partner_name': 'finfra',
            'access-token-generation': 'Not clicked'
        });
    }, []);

    return ( 
            <div>
                <div>
                    <nav className="">
                        <img src={require('../components/images/images/finfra_logo.png')} alt="finfra_logo" height="60" className='pwc-logo' />
                    </nav>
                    <div className="google-translate">
                        <div className={`notranslate google-translate-btn google-translate-en ${!isTranslated ? 'google-translate-btn-selected' : ''}`} onClick={() => translate('en')}> EN </div>
                        <div className={`notranslate google-translate-btn ${isTranslated ? 'google-translate-btn-selected' : ''}`}  onClick={() => translate('id')}> ID </div>
                    </div>    
                </div>
                <div className="main-conatiner">
                    <div className="main-class-nui">
                        <h3 className='to-heading-nui'>
                            {
                                isTranslated 
                                ? `${clientName} menggunakan PowerCred untuk menghubungkan akunmu`
                                : `${clientName} uses PowerCred to connect your account`
                            }
                        </h3>
                        <h4 className='to-heading2-nui'>
                            {
                                isTranslated 
                                ? `${clientName} tidak akan mengakses akun atau data tanpa seizinmu`
                                : `${clientName} does not have access to your account or be able to access your data without your consent.`
                            }
                        </h4>
                        <div>
                            <div className='xyzq'>
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M6 6c0-3.311 2.689-6 6-6s6 2.688 6 6v4h3v14h-18v-14h3v-4zm14 5h-16v12h16v-12zm-13-5v4h10v-4c0-2.76-2.24-5-5-5s-5 2.24-5 5z"/></svg>
                                <span>
                                    {
                                        isTranslated 
                                        ? 'Keamanan data'
                                        : 'Data Security'
                                    }
                                </span>
                            </div>
                            <div className='xyzq'>
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c-3.371 2.866-5.484 3-9 3v11.535c0 4.603 3.203 5.804 9 9.465 5.797-3.661 9-4.862 9-9.465v-11.535c-3.516 0-5.629-.134-9-3zm0 1.292c2.942 2.31 5.12 2.655 8 2.701v10.542c0 3.891-2.638 4.943-8 8.284-5.375-3.35-8-4.414-8-8.284v-10.542c2.88-.046 5.058-.391 8-2.701zm5 7.739l-5.992 6.623-3.672-3.931.701-.683 3.008 3.184 5.227-5.878.728.685z"/></svg>
                                <span>
                                    {
                                        isTranslated 
                                        ? 'Privasi dilindungi'
                                        : 'Privacy Protected'
                                    }
                                </span>
                            </div>
                            <div className='xyzq'>
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M23.621 9.012c.247.959.379 1.964.379 3 0 6.623-5.377 11.988-12 11.988s-12-5.365-12-11.988c0-6.623 5.377-12 12-12 2.581 0 4.969.822 6.927 2.211l1.718-2.223 1.935 6.012h-6.58l1.703-2.204c-1.62-1.128-3.582-1.796-5.703-1.796-5.52 0-10 4.481-10 10 0 5.52 4.48 10 10 10 5.519 0 10-4.48 10-10 0-1.045-.161-2.053-.458-3h2.079zm-7.621 7.988h-8v-6h1v-2c0-1.656 1.344-3 3-3s3 1.344 3 3v2h1v6zm-5-8v2h2v-2c0-.552-.448-1-1-1s-1 .448-1 1z"/></svg>
                                <span>
                                    {
                                        isTranslated 
                                        ? 'Password dilindungi'
                                        : 'Password Protected'
                                    }
                                </span>
                            </div>
                        </div>
                        <h4 className='to-heading3-nui'>
                            <span>
                                <span className='to-heading3-nui-text'>
                                    {
                                        isTranslated 
                                        ? 'Mengapa terkoneksi dengan PowerCred? Cari tahu lebih lanjut'
                                        : 'Why connect with PowerCred? Know more'
                                    }
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                                <div className='partner-info-icon-message'>
                                    {
                                        isTranslated 
                                        ? `PowerCred membantu anda memanfaatkan kekuatan dari Data Alternatif dengan memungkinkan ${clientName} untuk mendapatkan akses dan insight dari data yang anda setujui. Data ini membantu anda untuk mendapatkan akses kredit dan jasa keuangan lainnya dengan cepat dan mudah, tanpa mengganggu keamanan data dan privasi anda.`
                                        : `PowerCred helps you leverage the power of your alternate data by enabling ${clientName} to gain access and insights from your consented data. This helps you gain access to credit and other financial services in a quick and hassle-free manner, without compromising on your data security and privacy.`
                                    }
                                </div>
                            </span>
                        </h4>
                        <div className={'input-front-page marb-0'}>
                            <input
                                type="text"
                                id='user_name'
                                className='form-control inp-uname'
                                placeholder={isTranslated ? 'Nama' : 'Name'}
                                name='user_name'
                                onChange={(e) => changeValue("user_name", e.target.value)}
                                value={info.user_name}
                                required
                            />
                        </div>
                        <div className={showCountryCode ? 'form-group-pn input-front-page marb-0' : 'input-front-page marb-0'}>
                            {
                                showCountryCode && <span className="border-end country-code">+62</span>
                            }
                            <input
                                type="number"
                                id='user_id'
                                className='form-control inp-phone'
                                placeholder={isTranslated ? 'Nomor telepon' : 'Phone number'}
                                name='user_id'
                                onChange={(e) => changeValue("user_id", e.target.value)}
                                value={info.user_id}
                                required
                            />
                        </div>
                        <div className={'input-front-page'}>
                            <input
                                type="email"
                                id='user_email'
                                className='form-control inp-uname'
                                placeholder={isTranslated ? 'Email' : 'Email'}
                                name='user_email'
                                onChange={(e) => changeValue("user_email", e.target.value)}
                                value={info.user_email}
                                required
                            />
                        </div>
                        <div>
                            <div key={`inline-checkbox`} className="mb-3">
                                <Form.Check
                                    inline
                                    name="group1"
                                    type="checkbox"
                                    id={`inline-checkbox`}
                                    checked={check}
                                    onChange={() => setCheck(!check)}
                                />
                                <p className='m-check'>
                                    {
                                        isTranslated
                                        ? <>  Dengan mencentang ini, Anda menyetujui <a onClick={() => masterConsent()} className='a-m-check'>Kebijakan Perlindungan dan Berbagi Data</a> PowerCred, <a href='https://www.powercred.io/terms-of-service' className="a_master_cb" target="_blank">Ketentuan Layanan</a> dan <a href='https://www.powercred.io/privacy-policy' className="a_master_cb" target="_blank">Kebijakan Privasi</a>. </>
                                        : <> By checking this you agree to PowerCred's <a onClick={() => masterConsent()} className='a-m-check'>Data Sharing and Protection Policy</a>, <a href='https://www.powercred.io/terms-of-service' className="a_master_cb" target="_blank">Terms of Service</a> and <a href='https://www.powercred.io/privacy-policy' className="a_master_cb" target="_blank">Privacy Policy</a>. </>
                                    }
                                </p>
                            </div>
                        </div>
                        <button type="button" className='btn nav-btn-pwc continue-btn' onClick={generateToken}>
                            {
                                isTranslated 
                                ? 'Selanjutnya'
                                : 'Continue'
                            }
                        </button>
                        {/* <h5 className='to-heading4-nui'>
                            {
                                isTranslated 
                                ? <> Dengan mengklik Selanjutnya, berarti kamu telah menerima <a href='https://www.powercred.io/terms-of-service' className="a_master_cb" target="_blank">Persyaratan Layanan</a> dan <a href='https://www.powercred.io/privacy-policy' className="a_master_cb" target="_blank">Kebijakan privasi</a> PowerCred </>
                                : <> By clicking on continue you accept <a href='https://www.powercred.io/terms-of-service' className="a_master_cb" target="_blank">Terms of service</a> and <a href='https://www.powercred.io/privacy-policy' className="a_master_cb" target="_blank">Privacy policies</a>  of PowerCred </>
                            }
                        </h5>     */}
                        <h6 className='to-heading5-nui'>
                            {/* {
                                isTranslated 
                                ? 'Teregistrasi oleh Kominfo'
                                : 'Registered by Kominfo'
                            } */}
                            <img src={require('../components/images/images/kominfo.png')} alt="kominfo_logo"  width="50" className='kominfo-logo'/>
                            <img src={require('../components/images/images/aftech.png')} alt="aftech_logo" height="50" className='aftech-logo' />
                            <img src={require('../components/images/images/iso.png')} alt="iso_logo" height="50" className='iso-logo' />
                        </h6>
                    </div>
                </div>
                {isLoading && <FullPageLoader />}
                {isMessage && <Message isTranslated={isTranslated} message={isMessage} closeMessage={() => setIsMessage(null)}/>}
                {isError && <Error isTranslated={isTranslated} closeResponse={() => setIsError(false)}/>}
                {isConsent && <ConsentModal closeResponse={() => setIsConsent(false)} isTranslated={isTranslated}/>}
        </div>
     );
}
 
export default Home;
